@import '../../../../style/index.scss';

.CompanyProfile {
  padding: 32px 0;

  .container {
    display: flex;
    flex-direction: column;

    .left-content {
      color: $brand-blue;
    }

    .right-content {
      display: grid;
      grid-template-rows:  repeat(2, 150px);
      grid-template-columns:  repeat(2, 150px);

      justify-items: center;
      align-items: center;
      box-sizing: border-box;
      align-self: center;
      row-gap: 20px;
      column-gap: 20px;

      margin: 20px 0;

      .box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: linear-gradient(to bottom,
            rgba($brand-cyan, 1) 0%,
            rgba($brand-blue, 1) 100%);
        padding: 20px;
        box-sizing: border-box;
        color: $brand-white;

      }
    }
  }

  @media (min-width: $screen-md-min) {
    padding: 62px 0;

    .container {
      display: flex;
      flex-direction: row;

      .left-content {
        margin-right: 60px;
        align-items: flex-start;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }

      .right-content {
        grid-template-rows: repeat(2, 200px);
        grid-template-columns: repeat(2, 200px);
      }
    }
  }
}