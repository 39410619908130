
@import '../../style/index.scss';

.ParallaxMainHeadline {
  height: 40vh;
  position: relative;

  .ParallaxMainHeadline-banner {
    height: 100%;
    width: 100%;
  }

  .overlay {
    position: absolute;
    background-image: linear-gradient(to bottom, rgba($brand-darkblue, 1) 2%, rgba($brand-darkblue, 0) 53%);
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .ParallaxMainHeadline-content {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;

    .ParallaxMainHeadline-content-container {
      .product-text {
        color: $brand-white;
      }
    }
  }
}


// @media (min-width: $screen-md-min) and (max-width: $screen-xl-min) {
//   .parallax-container {
//     height: 450px;
//   }
// }
