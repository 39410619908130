@import '../../style/index.scss';

.Product {
  width: 100%;
  height: 100%;
  background-color: $white;


  .d-none {
    display: none;
  }

  .product-content-container {
    width: 100%;
    padding: 0 80px;
    justify-content: center;
    padding: 0;

    .sidebar {
      width: 100%;
    }

    .main {
      width: 100%;
      margin-bottom: 30px;
      margin-top: 16px;

      .main-content {
        width: 100%;

        .sort-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;

          div:nth-child(1) {
            color: $brand-blue;
          }

          .sorting-dropdown {
            padding: 4px 12px;
            border: 1px solid black;
            color: $brand-blue;
            width: fit-content;
            display: flex;
            flex-direction: row;
            margin-left: 16px;

            .chevron {
              transition: all 1s;
              margin-left: 70px;
              color: $brand-blue;
              font-weight: $font-weight-regular;

              &[ascending="false"] {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      .pagination-container {
        margin: 24px 0;
      }
    }
  }

  @media (min-width: $screen-md-min) {

    .product-content-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 40px 80px;

      .sidebar {
        width: 400px;
        padding-right: 30px;
      }

      .main {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 30px;
        margin-top: 16px;

        .main-content {
          width: 100%;
          height: 100%;
          padding-left: 30px;
          display: flex;
          flex-direction: column;
          border-left: 1px solid black;

          .sort-container {
            justify-content: flex-end;
          }
        }


      }
    }
  }

  @media (min-width: $screen-md-min) and (max-width: $screen-xl-min) {

  }

  // @media (min-width: $screen-xl-min) {}
}