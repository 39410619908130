@import '../../../../style/index.scss';

.Brand {
  width: 100%;
  text-align: center;

  .brand-title {
    text-align: center;
    margin: 16px 0;
    width: 100%;
  }
  .brand-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
  }
  .clear-btn {
    cursor: pointer;
  }
  .brand-item-container {
    display: flex;
    overflow-x: scroll;
    padding-bottom: 8px;

    &::-webkit-scrollbar-thumb {
      background: $brand-darkblue;
      border-radius: 20px;
    }

    &::-webkit-scrollbar {
      background-color: $brand-lightblue;
      height: 10px;
    }

    .brand-item {
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

  }

  @media (min-width: $screen-md-min) {
    .brand-title {
      text-align: left;
    }

    .brand-item-container {
      display: flex;
      flex-wrap: wrap;
      overflow-x: auto;
      flex-grow: 1;

      .brand-item {
        margin: 0 8px 8px 0;
      }
    }

  }
}