@import '../../../../style/index.scss';

.BlogPost {
  .gallery-header{
    margin-bottom: 30px;
  }
  .carousel-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .carousel {
      display: flex;
      flex: 1;
    }

    .circle-btn {
      margin: 0 8px;
    }
  }


  .slide-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 60px;
    background-color: $brand-darkblue;
    color: $white;
    padding: 20px;

    div {
      color: $white;
    }

    .slide-title,
    .slide-body {
      margin-bottom: 12px;
    }

    .slide-body {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .readmore-btn {
      cursor: pointer;
      color: $white;
      width: fit-content;
      border-bottom: 1px solid $brand-darkblue;

      &:hover {
        border-bottom: 1px solid $white;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .circle-btn {
      margin: 0 20px;
    }

    .swiper-container {
      width: 768px;
    }

  }

}