@import '../../style/index.scss';

.RightButtonOvalButton {
  width: fit-content;
  padding: 10px 70px 10px 24px;
  border-radius: 0px 0px 50px;
  transition: 0.5s;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: 1px solid $brand-blue;
  background-color: $white;
  color: $brand-lightblue;
  b {
    margin: 0;
    font-weight: $font-weight-bold;
  }
}

.RightButtonOvalButton:hover {
  background: linear-gradient(to right, rgba($brand-cyan,1) 0%, rgba(255,255,255,0) 100%);
}
