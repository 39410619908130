@import '../../style/index.scss';

.Gallery {
  width: 100%;
  height: 100%;
  background-color: $white;
  padding-bottom: 50px;

  .gallery-header {
    color: $brand-darkblue;
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
  }

  .gallery-container {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .gallery-grid {
    display: grid;

    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    row-gap: 10px;
    column-gap: 10px;

    width: 100%;

    .gallery-item {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .gallery-pagination {
    margin-top: 24px;
    margin-bottom: 80px;
    width: 100%;
  }


  .download-table {
    border-bottom: 1px solid $brand-darkblue;
    width: 100%;

    .rdt_TableCell:not(:last-child) {
      border-right: 1px solid $brand-darkblue;
    }

    .rdt_TableCol:not(:last-child) {
      border-right: 1px solid $white;
    }

    .rdt_TableHeadRow {
      background-color: $brand-darkblue;
      color: $white;
      font-weight: $font-weight-bold;
    }

    .rdt_TableRow {
      border-color: $brand-darkblue;
      color: $brand-darkblue
    }
  }

  .icon-btn {
    background-color: $brand-lightblue;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: $brand-cyan;
      .table-icon {
        color: $brand-blue;
      }
    }

    .table-icon {
      color: $white;
    }
  }
}