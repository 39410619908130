@import '../../../../style/index.scss';

.Category {
  width: 100%;

  .category-title {
    text-align: center;
    margin: 8px 0;
  }

  .category-list {
    &::after {
      content: '';
      display: flex;
      width: 100%;
      height: 1px;
      background-color: $brand-blue;
    }

    .category {
      font-weight: $font-weight-regular;
      text-align: center;
      width: 100%;
    }

    .category-active {
      font-weight: $font-weight-bold;
    }

    .sub-category-container {

      .sub-category {
        cursor: pointer;
        background-color: white;
        color: $brand-blue;
        padding: 8px 16px;
        text-align: center;
      }

      .sub-category-active {
        font-weight: $font-weight-bold;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .category-title {
      text-align: left;
    }

    .category-list {
      .category {
        text-align: left;
      }

      .sub-category-container {
        .sub-category {
          text-align: left;
          padding: 8px 24px;
        }
      }
    }
  }
}