@import '../../style/index.scss';

.ProductItem {
  display: flex;
  flex-direction: column;
  border: 1px solid $brand-lightblue;
  cursor: pointer;

  .product-img {
    position: relative;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid $brand-lightblue;
    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      object-fit: contain;
    }

    .product-bg-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .name {
    min-height: 60px;
    height: 100%;
    padding: 8px;
    color: $brand-darkblue;
    background-color: $white;
    word-wrap: break-word;
  }

  &:hover {
    .product-img {
      border-bottom: 1px solid $brand-cyan;
    }

    .product-bg-overlay {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba($brand-cyan, 1) 100%);
    }

    .name {
      background-color: $brand-cyan;
    }
  }

}

// .ProductItem:hover {
//   border: 1px solid black;
// }