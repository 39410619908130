@import '../../style/index.scss';

.ProductDetail {
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  max-height: 70vh;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
  .close-popup-btn {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
      background-color: grey;
    }
  }
  .images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    .preview {
      max-width: 350px;
      max-height: 350px;
      width: 100%;
      height: 100%;
      margin-bottom: 8px;


      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .img-list {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;

      .circle-btn-left,
      .circle-btn-right {
        width: 20px;
        height: 20px;
      }


      .img-small {
        height: 70px;
        width: 70px;
        &[is-hidden="true"] {
          display: none;
        }

        &[is-hidden="false"] {
          display: block;
        }

        &[is-selected="true"] {
          border: 1px solid blue;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .product-detail {
    width: 100%;
    height: 100%;
    padding: 16px;
    color: $brand-darkblue;
   
    .product-name {
      &::after {
        content: '';
        display: flex;
        background-color: $brand-blue;
        width: 100%;
        height: 2px;
        margin: 16px 0;
      }
    }
    .product-description  {
      margin-bottom: 20px;
    }
    .product-name {
      color: $brand-blue;
    }

    .product-spesification {
      margin-bottom: 8px;
    }

    .product-price {
      color: $brand-blue;
      margin-bottom: 16px;
    }

    .purchase-link {
      padding: 8px 16px;
      background-color: $brand-darkblue;
      color: $white;
      display: block;
      width: fit-content;
    }
  }

  @media (min-width: $screen-md-min) {
    overflow-y: scroll;
    flex-direction: row;
    max-width: 900px;
    max-height: 600px;
    height: 100%;
    padding: 20px;
    .close-popup-btn {
      top: 20px;
    }
    .product-detail {
      .purchase-link {
        padding: 8px 16px;
        background-color: $brand-darkblue;
        color: $white;
        display: block;
        width: fit-content;
      }
    }

  }
}