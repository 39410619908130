$red: red;
$green: green;
$blue: blue;
$yellow: yellow;

$blue-primary: #18328F;
$blue-secondary: #2E62CC;
$dark-grey: #353535;

$white: #FFFFFF;
$black: black;
$brand-white: #fffafe;
$brand-cyan: #b0e1e4;
$brand-cyan2: #6ed3d3;
$brand-lightblue: #5f96bd;
$brand-blue: #126e9f;
$brand-darkblue: #1b4273;

