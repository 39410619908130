@import '../../../../style/index.scss';

.ProductList {
  .product-grid {
    display: grid;

    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;
    margin: 0 8px;
  }

  .menu {
    width: 200px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
  }

  .menu-item {
    cursor: pointer;
    padding: 5px;
    height: 28px;
    border-bottom: 1px solid rgb(187, 187, 187);
  }

  .menu-item:hover {
    color: #2980b9;
  }

  .skeleton-container {
    width: 100%;
    height: 250px;
  }
  @media (min-width: $screen-md-min) {
    .product-grid {
      margin: initial;
    }
    .skeleton-container {
      width: 100%;
      height: 450px;
    }
  }
}