@import '../../../style/index.scss';

.Modal {
  position: fixed;
  z-index: 100;
  display: flex;
  background-color: $white;
  transform: translate(calc(50vw - 50%), calc(50vh - 50%));
}

.Modal-enter-active {
  animation: enterAnimation 0.2s ease-out forwards;
}

.Modal-exit-active {
  animation: exitAnimation 0.2s ease-out forwards;
}



@keyframes enterAnimation {
  0% {
    // transform: translate(-50%, 100%);
    opacity: 0; 
  }

  100% {
    // transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes exitAnimation {
  0% {
    // transform: translate(-50%, -50%);
    opacity: 1;
  }

  100% {
    // transform: translate(-50%, 100%);
    opacity: 0;
  }
}