@import '../../../../style/index.scss';

.ClientAndPartners {
  padding: 60px 0 120px 0;
  .product-text {
    text-align: center;
    color: $brand-blue;
  }
  .and-more {
    text-align: center;
    color: $brand-blue;
    margin-top: 40px ;
  }
  .partner-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
    .partner-item {
      width: calc(100% / 2);
      height: 120px;
      margin: 10px 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  @media (min-width: $screen-md-min) {
    .partner-row {
      .partner-item {
        width: calc(100% / 5);
      }
    }
  }
}