@font-face {
  font-family: 'Sitka-Regular';
  src: url('../fonts/Sitka/Sitka-Regular.ttf');
}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('../fonts/Inter/Inter-ExtraLight.ttf');
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('../fonts/Inter/Inter-Medium.ttf');
}

$font-weight-light:300;
$font-weight-regular: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;