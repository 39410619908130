@import '../../style/index.scss';

.Home {
  height: 100%;
  background-color: $white;
  min-height: 100vh;
  .text-align-center { 
    text-align: center;
  }
  .btn-text {
    color: $brand-lightblue;
  }

  .btn-text:hover {
    color: $white;
  }

  .our-text {
    color: $brand-lightblue;
  }

  .cp-text {
    color: $brand-blue;
  }

  .title-text {
    color: $brand-blue;
  }

  .product-text {
    color: $brand-blue;
  }

  p {
    font-weight: 300;
    font-size: 1em;
  }

  .logo {
    width: 50%;
    height: 4.5em;
    margin-top: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .logo-text {
    color: $brand-darkblue;
  }
  .home-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: $white;
    box-sizing: border-box;
    background-color: $white;
    align-items: center;
  }

  .absolute-container {
    width: 100%;
    height: 100%;
    background-color: $white;
    flex: 1;
    padding-bottom: 100px;

    .parallax-container {
      height: 100vh;
      position: relative;

      .overlay {
        position: absolute;
        // https://cssgradient.io/
        background-image: linear-gradient(to bottom, rgba(27, 66, 115, 1) 2%, rgba(27, 66, 115, 0) 53%, rgba(176, 225, 228, 1) 92%, rgba(255, 255, 255, 1) 100%);
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }

    .parallax-banner {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 0;
      overflow: hidden;
    }

    .parallax-content {
      position: absolute;
      z-index: 2;
      top: 0;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      flex-direction: column;

      .content-container {
        width: 80%;

        .head-sentence {
          line-height: 1;
          color: $white;
          text-align: center;
        }

        .sliding-word-container {
          height: 7vw;
          overflow: hidden;
        }

        .text {
          opacity: 0;
          animation: fadeIn 1s ease-in-out;
          animation-fill-mode: forwards;

          line-height: 1;
          color: $white;
          text-transform: uppercase;
          text-align: center;

        }

        .text {
          animation: fadeIn 3s ease infinite;
        }

        .text-hidden {
          opacity: 0;
          animation: fadeOut 0.5s ease-in-out forwards;
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }

          30% {
            opacity: 1;
            transform: translateY(0px);
          }

          100% {
            opacity: 1;
            transform: translateY(0px);
          }
        }

        @keyframes fadeOut {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0;
            transform: translateY(-20px);
          }
        }

        .description {
          color: $white;
          text-align: center;

          span {
            // font-weight: 700;
            // font-size: 1em;
            margin: 0 0.4em;
          }
        }
      }

    }

    .about-content {
      margin-top: 60px;


      .content-flex {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin: 30px 0;

        .image-container {
          width: 35%;
          margin-right: 64px;
          height: 100%;
          aspect-ratio: 1;


          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .switcher-content-container {
          display: flex;
          flex: 1;
          flex-direction: column;

          .row {
            margin-right: 20%;
          }

          .switch {
            width: 80%;
            display: flex;
            align-items: center;
            padding: 10px 0;
            flex: 1;
            align-items: flex-start;
            margin-right: 20px;
            cursor: pointer;
            font-weight: 700;

            &:hover {
              border-bottom: 3px solid $brand-lightblue;
            }

            span {
              color: $brand-lightblue;
            }
          }

          .active {
            border-bottom: 3px solid $brand-cyan;

            span {}
          }

          .switch-content {
            background-color: white;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 20px 0;

            .equipment {
              display: flex;
              flex-direction: column;
              span {
                color: $brand-darkblue
              }
            }

            .specialization {
              display: flex;
              flex: 1;
              flex-direction: row;
              padding-top: 20px;
              padding-bottom: 20px;
              justify-content: flex-start;

              .specialization-wrap-border {
                background: linear-gradient(to top, rgba($brand-cyan, 1) 0%, rgba($brand-blue, 0.5) 20%, rgba(95, 150, 189, 0) 100%);
                padding: 2px;
              }

              .specialization-wrap-border:nth-child(2) {
                margin: 0 20px;
              }

              .specialization-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: $white;
                padding: 20px 30px;
                width: 100%;
                height: 100%;
                box-sizing: border-box;

                .specialization-img {
                  max-width: 150px;
                  max-height: 150px;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }

                }

                .specialization-text {
                  font-weight: 600;
                  color: $brand-blue;
                  font-size: 1.2em;
                  margin-top: 20px;
                  word-break: break-all;
                }
              }
            }
          }

        }
      }

      @media screen and (min-width: 0px) and (max-width: $screen-lg-min) {
        .content-flex {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          // padding: 0 20px;

          .image-container {
            width: 80%;
            height: 100%;
            margin: 0;
          }

          .switcher-content-container {
            width: 100%;
            margin-top: 20px;

            .row {
              margin-right: 0;
            }

            .switch {
              flex: 1;
              width: 100%;
            }

            .switch-content {
              .specialization {
                flex-direction: column;
                padding-top: 20px;
                justify-content: center;
                align-items: center;

                .specialization-container {
                  margin-right: 0;


                }

                .specialization-container:nth-child(n+2) {
                  margin-top: 20px;
                }
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: 0px) and (max-width: $screen-md-min) {
      .about-content {
        .logo {
          width: 80%;
        }

        .content-flex {
          .switcher-content-container {
            .switch {
              span {
                font-size: 1em;
              }
            }
          }
        }
      }
    }

    .products {
      display: flex;
      width: 100%;
      flex-direction: row;
      box-sizing: border-box;
      padding-top: 120px;
      background-color: $white;

      .content-sliders-container {
        display: flex;
        align-items: center;
        position: relative;
        width: 50%;
        margin-right: 50px;

        .left {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -50px;
          margin: auto 0;
        }

        .right {
          position: absolute;
          top: 0;
          bottom: 0;
          right: -50px;
          margin: auto 0;
        }

      
        .slider-img-container {
          display: flex;
          flex: 1;
          flex-direction: row;

          .left-img {
            margin-right: 8px;
          }

          .slider-img {
            display: flex;
            position: relative;
            flex-direction: column;
            height: 50vh;
            width: 100%;
            overflow: hidden;

            .slider-img-overlay {
              position: absolute;
              z-index: 1;
              background-image: linear-gradient(to top, rgba($brand-cyan2, 1) 0%, rgba(255, 255, 255, 0) 60%);
              width: 100%;
              height: 100%;

              display: flex;
              justify-content: flex-start;
              align-items: flex-end;
              padding: 20px 18px;
              box-sizing: border-box;

              .product-title {
                color: $white;
                transition: all 0.2s ease;
              }
            }

            .hidden-product-img {
              display: none;
            }

            img {
              transition: all 0.2s ease;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .slider-img:hover {
          img {
            transform: scale(1.1);
          }
        }
      }

      .product-container {
        display: flex;
        flex-direction: column;
        width: 40%;
        margin-left: 40px;


      }


    }

    @media screen and (min-width: 0px) and (max-width: $screen-md-min) {
      .products {
        flex-direction: column;

        .content-sliders-container {
          width: 70%;
          margin-right: 0;

          .slider-img-container {
            .slider-img {
              .slider-img-overlay {
                .product-title {
                  font-size: 1em;
                  word-break: break-all;
                }
              }
            }
          }

          .slider-img:hover {
            img {
              transform: scale(1.1);
            }
          }
        }

        .product-container {
          margin: 20px 0;
          width: 100%;
          box-sizing: border-box;
          padding: 0 20px;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .services {
      box-sizing: border-box;
      padding-top: 120px;

      .services-container {
        padding-top: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .services-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-right: 60px;
          box-sizing: border-box;
        }


        .services-detail {
          flex: 1;
          height: 50vh;

          .service-img {
            position: relative;
            width: 100%;
            height: 100%;
            display: none;
            aspect-ratio: 1;
            overflow: hidden;

            .overlay {
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              background-image: linear-gradient(to bottom, rgba(24, 50, 143, 0.5), 100%, rgba(255, 255, 255, 1));
              padding: 40px;
              overflow-y: scroll;

              .service-title {
                color: $white;
                border-bottom: 1px solid $white;
              }

              .service-list {
                font-weight: 300;
                color: $white;

                .list {
                  display: flex;
                  align-items: flex-start;
                  width: 100%;
                  margin: 20px 0;

                  .list-content {
                    font-weight: 300;
                    font-size: 1em;
                    color: $white;
                    width: 100%;
                  }

                  .dot {
                    font-size: 2em;
                    line-height: 15px;
                    color: $white;
                    margin-right: 20px;
                  }
                }
              }
            }

            .overlay::-webkit-scrollbar {
              display: none;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              filter: blur(6px);
              -webkit-filter: blur(6px);
            }
          }

          .show-img {
            display: block;
          }

        }
      }

      @media screen and (min-width: 0px) and (max-width: $screen-md-min) {
        .services-container {
          flex-direction: column;

          .services-list {
            margin: 0 0 20px 0;
          }

        }
      }

    }

  }

  .footer {
    width: 100%;
    min-height: 400px;
    height: 600px;
    position: fixed;
    bottom: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    background-color: $brand-lightblue;
    box-sizing: border-box;

    .footer-padding-top {
      padding-top: 80px;
    }

    .footer-bg {
      position: absolute;
      z-index: -2;
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .overlay {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba($brand-darkblue, 1) 15%, rgba($brand-darkblue, 0.6) 100%);
    }

    .footer-copyright {
      height: fit-content;

      .bordered-footer {
        padding: 20px;
        border-top: 1px solid $white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
      }

      .copyright-text {
        font-weight: 300;
        font-size: 0.9em;
        color: $white;
      }

      .sosmed-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex: 1;

        span {
          font-weight: 600;
          font-size: 0.9em;
          color: $white;
          margin: 0 30px;
        }

        :last-child {
          margin-right: 0px;
        }
      }
    }

    .footer-header-content {
      .footer-title {
        color: $white;
        margin-bottom: 18px;
      }

      .footer-logo {
        width: fit-content;
        height: 3em;
        margin-left: 2px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .footer-content {
      width: 100%;
      color: $white;
      display: flex;
      flex-direction: row;


      .footer-left {
        width: 40%;

        .map-container {
          height: 200px;
          width: 100%;
        }

        .mapouter {
          position: relative;
          text-align: right;
          width: 100%;
          height: 100%;

        }

        .gmap_canvas {
          overflow: hidden;
          background: none !important;
          width: 100%;
          height: 100%;
        }
      }



      .footer-list-product {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        width: 100%;
        flex: 1;
        margin-left: 60px;

        .product-name {
          font-weight: 300;
          font-size: 1em;
        }
      }
    }

  }

  @media screen and (min-width: 0px) and (max-width: $screen-md-min) {
    .logo {
      width: 100%;
      height: 4.5em;
    }

    .absolute-container {
      margin-bottom: 0px !important;
      .text {
        margin: 20px 0;
      }
    }

    .footer {
      position: relative !important;
      width: 100%;
      min-height: auto;
      height: 100%;
      position: fixed;
      bottom: auto;
      z-index: 2;
      display: flex;
      flex-direction: column;
      background-color: #18328F;
      box-sizing: border-box;

      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
      }

      .footer-bg {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .footer-header-content {
        padding: 0 20px;
        z-index: 1;
        flex: 1;

        .footer-title {
          text-align: center;
        }

        .footer-logo {
          width: auto;
          margin-left: 0;
        }
      }

      .footer-content {
        z-index: 1;
        flex-direction: column;

        .footer-left {
          flex: 1;
          width: auto;
          justify-content: center;

          p {
            padding: 0 20px;
          }

          .map-container {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            .mapouter {
              width: auto;
            }
          }
        }

        .footer-list-product {
          box-sizing: border-box;
          margin-left: 0;
          padding: 0 20px;
          column-gap: 20px;

          .product-name {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 10px;
          }
        }
      }

      .footer-copyright {
        z-index: 1;
        height: 100%;

        .bordered-footer {
          flex-direction: column;
          width: 100%;

          .copyright-text {
            text-align: center;
          }

          .sosmed-container {
            flex-direction: column;

            span {
              text-align: center;
            }

            :last-child {
              margin: 0 30px;
            }
          }
        }
      }
    }
  }
}