@import '../../style/index.scss';

.Contact {
  width: 100%;
  height: 100%;
  background-color: $white;
  padding-bottom: 50px;


  .heading1,
  .body {
    color: $brand-blue;
  }

  .address-container {
    .heading1 {
      margin-top: 62px;
    }
  }

  .address-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .address-detail {
    width: 100%;
    margin-top: 32px;
    text-align: center;
  }

  .map-container {
    width: 100%;
    height: 250px;
    margin-top: 32px;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .address-info-form-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 64px;
    padding-bottom: 80px;
  }

  .address-information-container {
    width: 100%;

    div {
      text-align: center;

      &:nth-child(2n+2):not(:first-child):not(:last-child) {
        margin-top: 8px;
        margin-bottom: 4px;
      }

      &:nth-child(6) {
        &:before {
          content: '';
          display: flex;
          width: 100%;
          height: 1px;
          background-color: $brand-blue;
          margin: 24px 0;
        }
      }
    }
  }

  .form-separator {
    display: none;
  }

  .message-form {
    margin-top: 30px;

    div:first-child {
      margin-bottom: 16px;
      text-align: center;
    }

    form {
      background-color: $brand-darkblue;
      display: flex;
      flex-direction: column;
      padding: 16px 24px;

      label {
        color: $white;
        margin-bottom: 8px;

        &:not(:first-child) {
          margin-top: 16px;
        }
      }

      input,
      textarea {
        background-color: inherit;
        outline: 1px solid white;
        border: none;
        color: $white;
        padding: 8px;
      }

      textarea {
        resize: vertical;
        margin-bottom: 24px;
        padding: 8px;
      }

      input,
      textarea,
      button {
        &::placeholder {
          color: lightblue;
        }

        &:active,
        &:focus-visible {
          outline: 2px solid $white;
        }

        &:disabled {
          &:hover {
            cursor: not-allowed;
          }

          cursor: not-allowed;
          pointer-events: none;
          background-color: $brand-blue;
        }
      }

      button {
        width: fit-content;
        align-self: end;

        &:hover {
          span {
            color: $white;
          }
        }

      }
    }
  }


  @media (min-width: $screen-md-min) {


    .address-info-form-wrapper {
      flex-direction: row;
      width: 100%;
      height: 100%;

      .form-separator {
        display: block;
        margin: 0 32px;
      }

      .address-information-container {
        width: 40%;

        div {
          text-align: left;
        }
      }

      .message-form {
        flex-grow: 1;
        margin-top: 0;
        div:first-child {
          text-align: initial;
        }
      }
    }
  }
}