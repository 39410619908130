@import '../../style/index.scss';

#Footer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  color: $brand-white;

  .footer-logo-text {
    display: flex;
    flex-direction: column;

    span {
      &:nth-child(2) {
        display: none;
      }
    }
  }

  .footer-overlay {
    background: linear-gradient(to bottom, rgba($brand-darkblue, 1) 15%, rgba($brand-darkblue, 0.6) 100%);
  }


  .footer-logo {
    display: flex;
    justify-content: center;

    padding: 32px 0;

    img {
      height: 40px;
      object-fit: contain;
      align-self: center;
      margin-right: 8px;
    }

  }

  .footer-content {
    .separator {
      width: 100%;
      height: 1px;
      background-color: $white;
      margin: 24px 0;
    }
  }

  .office-address-container {
    text-align: center;
  }

  .contact-us-container {
    div:first-child {
      margin-bottom: 18px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    .contact-btn {
      border: 1px solid $white;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      margin-bottom: 8px;
      padding: 8px;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      span {
        margin-left: 8px;
      }
    }
  }

  .footer-navigation-container {
    .body {
      margin-bottom: 18px;
      text-align: center;
    }

    .navigation-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .UnderlinedGradientButton {
        width: fit-content;
        margin: 0 20px;
      }

      .btn-content {
        padding: 8px 0;
      }
    }
  }

  .footer-copyright {
    p {
      border-top: 1px solid $white;
      padding: 24px 0;
      margin: 24px 0 0 0;
      text-align: center;
    }
  }

  @media (min-width: $screen-md-min) {

    .footer-logo {
      padding: 120px 0 62px 0;
    }

    .footer-logo-text {
      display: flex;
      flex-direction: row;

      span {
        &:nth-child(2) {
          display: initial;
          margin: 0 8px;
        }
      }
    }

    .office-address-container {
      text-align: left;
      width: 40%;
    }

    .contact-us-container,
    .footer-navigation-container {
      flex-grow: 1;
    }

    .footer-content {
      display: flex;

      .separator {
        display: block;
        width: 1px;
        height: initial;
        margin: 0 32px;
      }
    }

    .footer-navigation-container {
      .body {
        text-align: left;
      }

      .navigation-list {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;

        .UnderlinedGradientButton {
          width: fit-content;
          margin: 0;
        }
      }
    }

    .footer-copyright {
      p {
        margin: 64px 0 0 0;
      }
    }
  }
}