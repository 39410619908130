@import '../../../../style/index.scss';

.Specialization {
  width: 100%;
  height: 100%;
  margin: 70px 0;

  .product-text {
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
    color: $brand-blue;
  }

  .specialization-bg-overlay {
    background: linear-gradient(to bottom,
        rgba($white, 1) 5%,
        rgba($brand-blue, 0.8) 50%,
        rgba($white, 1) 95%);
  }

  .specialization-box-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;

    .specialization-box {
      width: 100%;
      height: 500px;

      max-width: 300px;
      max-height: 500px;

      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba($brand-darkblue, 0.9);
      margin: 0 auto;
      text-align: center;

      transition: all 0.2s ease;

      .specialization-img {
        width: 100%;
        height: fit-content;

        border-bottom: 8px solid $brand-white;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .title-text {
        color: white;
        margin: 20px 0 8px 0;
        word-break: break-all;
      }

      .title-text,
      p {
        color: white;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    height: 100vh;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .specialization-box-container {
      flex-direction: row;
      gap: 32px;

      .specialization-box {
        max-width: 390px;
        max-height: 500px;
        img {
          aspect-ratio: 3/2;
        }
        &:hover {
          transform: scale(1.1);
          background: rgba($brand-cyan, 0.8);

          .specialization-img {
            border-bottom-color: $brand-darkblue;
          
          }

          .title-text,
          p {
            color: $brand-darkblue;
          }
        }
      }

    }

  }
}