.container {
  box-sizing: border-box;
  padding: 0 80px;
  width: 100%;
  height: 100%;
}

.flex-1 {
  display: flex;
  flex: 1;
}

// Add a grid system for building responsive layouts
.row {
  display: flex;
  flex-direction: row;
}

.col {
  flex: 1;
  padding: 15px;
}

.page-container {
  height: 100%;
  min-height: 100vh;
}
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;

// Mobile
@media screen and (min-width: 0px) and (max-width: $screen-md-min) {
  .container {
    padding: 0 30px; // Reduce the padding even further on smaller screens
    display: flex;
    flex-wrap: wrap;
  }
}

// Desktop
@media screen and (min-width: 768px) and (max-width: $screen-lg-min) {

  //For Large Laptops
  .container {
    padding: 0 80px;
  }

  .col {
    flex: 1 1 100%;
  }
}