@import '../../style/index.scss';


.ImageWithOverlay {
  position: relative;
  z-index: 1;
  
  width: 100%;
  height: 100%;
  .overlay-container {
    position: absolute;
    z-index: -1;

    width: 100%;
    height: 100%;
  }

  .ImageWithOverlay-overlayimg {
    position: absolute;
    z-index: -2;

    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .ImageWithOverlay-content {
    width: 100%;
    height: 100%;
  }
}