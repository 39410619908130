@import '../../style/index.scss';

.navbar {
  background-color: $brand-darkblue;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 98;
  transition: top 1s;
  padding: 0;
  box-sizing: border-box;
}

.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 80px;
}

.nav-dropdown-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.nav-hide {
  // top: -10%;
}

.nav-logo {
  display: flex;
  align-items: center;

  .nav-text {
    display: block;
    color: $brand-white;
    margin-right: 20px;
  }
}

.nav-logo img {
  height: 60px;
  object-fit: contain;
  align-self: center;
  padding: 10px;
}

.dropdown-icon {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;

  .fa {
    color: $white;
    padding: 20px;
  }
}

.dropdown {
  background-color: rgba(46, 98, 204, 0.75);
  color: $white;
}

.nav-items {
  display: flex;
  flex-direction: row;
}

.nav-items .active {
  background: linear-gradient(to bottom, rgba($brand-cyan, 1) 0%, rgba(255, 255, 255, 0) 100%);
  ;
  color: $white;
  font-weight: 600;
  border-bottom: 2px solid $white;
}


.nav-item {
  display: flex;
  padding: 18px 24px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: $white;
  box-sizing: border-box;
}


.nav-item:hover,
.nav-icon:hover {
  display: flex;
  background: linear-gradient(to bottom, rgba($brand-lightblue, 1) 0%, rgba(255, 255, 255, 0) 100%);
  ;
  color: $white;
}

@media screen and (min-width: 0px) and (max-width: $screen-lg-min) {
  .nav-container {
    justify-content: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    flex-direction: column;
  }

  .nav-logo {
    .nav-text {
      font-size: 0.8em;
      display: flex;
      flex-direction: column;

      span {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }

  .dropdown-icon {
    cursor: pointer;
    display: flex;
    width: fit-content;

    .icon {
      width: 50px;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      div {
        width: 25px;
        height: 3px;
        border-radius: 20px;
        background-color: $white;
        margin: 3px 0;
      }
    }
  }

  .nav-items {
    display: none;
  }

  .dropdown-items {
    display: flex !important;
  }

  .nav-items {
    flex-direction: column;
  }
}