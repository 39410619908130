@import '../../style/index.scss';



.CustomProgressBar {
  width: 100%;
  height: 1px;
  background-color: $brand-blue;
  position: relative;

  .CustomProgressBar-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 3px;
    background-color: $brand-darkblue;
    transition: all 0.2s ease-in-out;
  }
}