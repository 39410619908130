@import '../../style/index.scss';

.PageWrapper {  
  .page-wrapper-container {
    width: 100%;
    height: 100%;
    position: relative;

    .absolute-scroll-top {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-50%);
      z-index: 2;

      div {
        width: 60px;
        height: 60px;
        font-size: 50px;
      }
    }

  }

  .bottom-container {
    position: unset;
  }

  @media (min-width: $screen-md-min) {
    .bottom-container {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: -2;
    }
  }
}