@import './style/index.scss';

* {
	font-family: 'Montserrat' !important;
	box-sizing: border-box;
	word-wrap: break-word;
	// Light 300
	// Regular 400
	// SemiBold 600
	// Bold 700
	// ExtraBold 800
}

html,
body {
	margin: 0;
	padding: 0;

}

#root,
.App {
	height: 100%;
	width: 100%;
	min-height: 100vh;
}

.circle-btn,
.circle-btn-left,
.circle-btn-right,
.circle-btn-top,
.circle-btn-bottom {
	z-index: 1;
	border: 1px solid $brand-lightblue;
	display: flex;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	// margin: 0px 8px;
	transition: all 0.2s ease-in-out;
	font-weight: 600;
	font-size: 24px;
	color: $brand-darkblue;
	background-color: $white;

	&-right {
		transform: rotate(180deg);
	}

	&-bottom {
		transform: rotate(270deg);
	}

	&-top {
		transform: rotate(90deg);
	}

	&::after {
		content: "<";
	}

	&:hover {
		background-color: $brand-cyan;
	}



	
}

@keyframes moveInLeft {
	0% {
		opacity: 0;
		transform: translateX(-100px);
	}

	60% {
		transform: translateX(10px);
	}

	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes moveInRight {
	0% {
		opacity: 0;
		transform: translateX(100px);
	}

	60% {
		transform: translateX(-10px);
	}

	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes moveInTop {
	0% {
		opacity: 0;
		transform: translateY(-100px);
	}

	60% {
		transform: translateY(10px);
	}

	100% {
		opacity: 1;
		transform: translate(0);
	}
}

@keyframes moveInBottom {
	0% {
		opacity: 0;
		transform: translateY(100px);
	}

	60% {
		transform: translateY(-10px);
	}

	100% {
		opacity: 1;
		transform: translate(0);
	}
}

.animate-moveInLeft {
	animation-name: moveInLeft;
}

.animate-moveInRight {
	animation-name: moveInRight;
}

.animate-moveInTop {
	animation-name: moveInTop;
}

.animate-moveInBottom {
	animation-name: moveInBottom;
}


.tag-line {
	font-size: 1.8em;
}

.heading1 {
	font-size: 2em;
}

.heading2 {
	font-size: 2em;
}

.heading3 {
	font-size: 1.8em;
}

.heading4 {
	font-size: 1.6em;
}

.subtitle1 {
	font-size: 1.5em;
}

.subtitle2 {
	font-size: 1.2em;
}

.subtitle3 {
	font-size: 1.1em;
}

.body {
	font-size: 1em;
}



.font-light {
	font-weight: $font-weight-light;
}

.font-regular {
	font-weight: $font-weight-regular;
}

.font-semibold {
	font-weight: $font-weight-semibold;
}

.font-bold {
	font-weight: $font-weight-bold;
}

.font-extrabold {
	font-weight: $font-weight-extrabold;
}



@media (min-width: $screen-md-min) {
	.tag-line {
		font-size: 6em;
	}

	.heading1 {
		font-size: 3em;
	}

	.heading2 {
		font-size: 2em;
	}

	.heading3 {}

	.heading4 {}

	.subtitle1 {
		font-size: 2.5em;
	}

	.subtitle2 {
		font-size: 1.8em;
	}

	.subtitle3 {
		font-size: 1.2em;
	}

	.body {
		font-size: 1em;
	}

}

// ::-webkit-scrollbar {
//   display: none;
// }