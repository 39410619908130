@import '../../style/index.scss';

.Dropdown {

  position: relative;

  .dropdown-content-enter {
    opacity: 0;
    transform: translateY(-5px);
    height: 0;
  }

  .dropdown-content-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s, transform 0.5s, height 1s;
    height: auto;
  }

  .dropdown-content-exit {
    opacity: 1;
    transform: translateY(0);
    height: auto;
  }

  .dropdown-content-exit-active {
    opacity: 0;
    height: 0;
    transform: translateY(-5px);
    transition: opacity 0.3s, transform 0.3s, height 1s;
  }
}