@import '../../style/index.scss';


.OverlayedButton {
  word-wrap: break-word;
  cursor: pointer;
  padding: 10px 8px;
  color: $brand-blue;
  background: $white;
  outline: inherit;
  font: inherit;
  border: none;
  width: auto;
  &:hover {
    background: linear-gradient(to right, rgba($brand-cyan, 1) 0%, rgba(255, 255, 255, 0) 100%);
    color: $brand-blue;
  }
  &:disabled span {
    color: $brand-cyan;
  }
}


.OverlayedButton__selected {
  background-color: $brand-blue;
  color: $white !important;
}

.OverlayedButton__border-btm {
  border-bottom: 1px solid $brand-lightblue;
}

.OverlayedButton__border-top {
  border-top: 1px solid $brand-lightblue;
}

.OverlayedButton__border-left {
  border-left: 1px solid $brand-lightblue;
}
.OverlayedButton__border-right {
  border-right: 1px solid $brand-lightblue;
}
