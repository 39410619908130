@import '../../style/index.scss';

#child-content {
  position: relative;

  .hidden {
    left: 130%;
  }
}



.CustomScrollbar {
  position: fixed;
  display: flex;

  height: 60vh;
  left: 97%;
  top: 50%;
  transform: translate(-50%, -50%);

  bottom: 50%;
  z-index: 97;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease;

  .text {
    writing-mode: vertical-rl;
    font-weight: 300;
    color: $brand-lightblue;
    transform: rotate(180deg);
    span {
    font-weight: 700;
    color: $brand-lightblue;
    }
  }

  .scrollbar {
    position: relative;
    width: 1px;
    display: flex;
    flex: 1;

    margin-top: 20px;
    background-color: $brand-lightblue;
    justify-content: center;

    .scrollbar-active {
      position: absolute;
      width: 5px;

      background-color: $brand-lightblue;
      height: 50px;
      top: calc(0% - 50px);

      border-radius: 8px;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: $screen-md-min) {
  .CustomScrollbar {
    display: none;
  }
}