@import '../../style/index.scss';

.Pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  div:first-child {
    margin-right: 8px;
  }

  div:last-child {
    margin-left: 8px;
  }

  .page-number {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 35px;
    min-height: 35px;
    padding: 6px;
    margin: 0 4px;
    border-radius: 50%;
    color: $brand-blue;
    font-weight: $font-weight-bold;

    &:hover {
      cursor: pointer;
    }
  }

  .active {
    background-color: $brand-blue;
    color: $white;
  }

  .disabled {
    cursor: not-allowed;
  }
}