@import '../../style/index.scss';


.UnderlinedGradientButton, a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  .btn-content {
    padding-top: 4px;
  }

  .bot-border {
    height: 4px;
    width: 100%;
    margin-top: 2px;
  }

  .active-border {
    background: linear-gradient(to right, rgba($brand-cyan, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
  &:hover .bot-border {
    background: linear-gradient(to right, rgba($brand-cyan, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &:hover .btn-content {
    font-weight: 600;
  }

}