@import '../../style/index.scss';

.Services {
  height: 100%;
  background-color: white;
  width: 100%;
  padding-bottom: 120px;

  .satisfaction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    div {

      &:nth-child(1) {
        margin-bottom: 50px;
        color: $brand-blue;
      }

      &:nth-child(2),
      &:nth-child(3) {
        margin-bottom: 24px;
        color: $brand-blue;
      }

      text-align: center;
      width: 80%;
    }
  }

  .carousel {
    width: 100%;
    height: 100%;
    position: relative;

    .circle-btn-left,
    .circle-btn-right {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    .circle-btn-left {
      left: 20px;
    }

    .circle-btn-right {
      right: 20px;
    }
  }

  .carousel-content {
    display: flex;
    width: 100%;
    min-height: 600px;
    color: $white;
    flex-direction: column;
    justify-content: center;
    padding: 20%;

    .heading1 {
      margin-bottom: 20px;
    }
  }

  .carousel-content-active {

    .slide-title,
    .slide-body {
      color: $brand-darkblue;
    }
  }

  .slide-overlay {
    background: linear-gradient(to bottom,
        rgba($white, 0.8) 0%,
        rgba($brand-darkblue, 0.7) 30%,
        rgba($brand-darkblue, 0.7) 70%,
        rgba($white, 0.8) 100%);
  }

  .slide-overlay-active {
    background: rgba($white, 0.8);
  }

  .progress-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 50px 0;

    .progress-bar {
      width: 70%;
    }
  }

  .email-input-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div:first-child {
      color: $brand-blue;
      text-align: left;
      width: 100%;
      margin: 42px 0;
    }

    input,
    textarea,
    button {
      margin-bottom: 24px;
      border: 1px solid $brand-blue;
      color: $brand-blue;

      &::placeholder {
        color: $brand-blue;
      }

      &:active,
      &:focus-visible {
        outline: 1px solid $brand-blue;
      }

      &:disabled {
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    input,
    textarea {
      width: 100%;
      padding: 8px 16px;
    }

    textarea {
      resize: vertical;
    }

    button {
      padding: 8px 16px;
    }

  }
  @media (min-width: $screen-md-min)  {
    .slide-title {
      font-size: 2.5em;
    }
  }

}